<template>
  <v-container class="py-6">
    <div class="text-center">
      <img
        src="/images/v-logo-small2.fw.png"
        aspect-ratio="16/9"
        alt=""
        height="41"
        class="mr-1 ourimage"
        @click="goToHome()"
      >
      <br />
      &nbsp;
    </div>
    <v-card class="pa-3 mx-auto" max-width="500">
      <v-card-title class="justify-center">
        <v-divider class="my-1"></v-divider>
        <div class="mx-2">Sign In</div>
        <v-divider class="my-1"></v-divider>
       
      </v-card-title>
      <v-card-text>
        <v-text-field v-model="email" label="Email" outlined></v-text-field>
        <v-text-field
          v-model="password"
          label="Password"
          :type="showIcon ? 'text' : 'password'"
          outlined
          :append-icon="showIcon ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showIcon = !showIcon"
        ></v-text-field>
        <v-btn
          block
          class="success"
          x-large
          :disabled="signinBtnStatus"
          @click="signIn"
        >
          {{ signinBtnText }}
        </v-btn>

        <div class="text-center mt-2 text-body-2">
          <router-link to="#">Register</router-link>
          <!--<router-link to="#">Forgot your password?</router-link>-->
          <div class="grey--text"> <i>Mode:{{ mode }}</i></div>
        </div>
      </v-card-text>
    </v-card>
    <v-snackbar v-model="snackbar" right>
      {{ snackbartext }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
//import router from '../router'
import httpServ from '../services/HTTPService'

export default {
  data: () => ({
    email: 'demo@infdb.com',
    mode:'demo',//'active',//demo
    password: 'infdb001',
    showIcon: false,
    signinBtnStatus: false,
    signinBtnText: 'Sign In',
    snackbar: false,
    snackbartext: ''
  }),
  created() {
    //httpServ.clearSession()
    httpServ.removeSessionJson('memberData')
    httpServ.removeSessionJson('archiveData')
    httpServ.removeSessionJson('farmerData')
  },
  methods: {
    goToHome: function () {
      window.location.href = httpServ.getHomeUrl()
    },
    signIn: function () {
      this.signinBtnStatus = true
      this.signinBtnText = 'Processing ...'

      const loginData = {
        email: this.email,
        password: this.password
      }

      const url = httpServ.getFarmerAuthenUrl('/infdb/demo_enumerator/login')
      const resp = httpServ.postFetch(url,loginData)

      resp.then((result) => {
        if (result.status === 200) {
          this.snackbartext = 'Login Successful'
          this.snackbar = true
          this.signinBtnText = 'Successful'

          //result.data[0].mode = 'active'
          const memberData = result.data

          httpServ.setSessionJson('memberData',memberData)

          setTimeout(() => {
            this.$router.push({ name: 'dashboard' })
          }, 1000)
        } else {
          this.snackbartext = result.message
          this.snackbar = true
          this.signinBtnStatus = false
          this.signinBtnText = 'Sign In'
        }
      })
    }
  }
}
</script>
<style scoped>
.ourimage:hover {
  cursor: pointer;
}
</style>
