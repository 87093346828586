<template>
  <Layout is-demo>
    <h1 class="text-h4 ms-0 mb-1">Dashboard</h1>
    <v-alert
      dense
      text
    >
      <ul>
        <li>You can only operate and view data within your coverage area</li>
        <li>All enteries you make will be save against you. Make sure only true farmer with correct information are captured</li>
        <li>You wil be penalies if your cature record falls out the agreed cluster or LGA </li>
      </ul>
    </v-alert>
    <v-card class="mt-2">
      <v-card-subtitle class="font-weight-bold green--text">Coverage</v-card-subtitle>
      <v-divider></v-divider>

      <v-card-text><b>State(s):</b> {{ work_state }}</v-card-text>
      <div v-if="hasActiveAccount">
        <v-card-text><b>Start Date:</b> {{ startDate | moment("dddd, MMMM Do YYYY") }}</v-card-text>
        <v-card-text><b>End Date:</b> {{ stopDate | moment("dddd, MMMM Do YYYY") }}</v-card-text>
        <v-card-text><b>Remaining Days:</b> {{ stopDate | moment("from", "now", true) }}</v-card-text>
      </div>
    </v-card>
    <v-card class="mt-2">
      <v-card-subtitle class="font-weight-bold green--text">Operation Summaries</v-card-subtitle>
      <v-divider></v-divider>
      <v-card-text><b>Registered Cooperatives:</b> {{ cooperatives }}</v-card-text>
      <v-card-text><b>Verified Farmers:</b> {{ farmers }}</v-card-text>
      <div> policy**,registeration,picture adjust**, share phone,education, age</div>
    </v-card>
  </Layout>
</template>

<script>
import Layout from '@/nfmp_components/layouts/MainPortalLayout'
import httpServ from '../services/HTTPService'
import moment from 'moment'

export default {
  components: {
    Layout
  },
  data() {
    return {
      states:'...loading',
      window: '...loading',
      startDate: '',
      stopDate:'',
      farmerId:'',
      cooperatives: '...loading',
      farmers: '...loading',
      hasActiveAccount: false,

      alterLOBFlag: false,

      memberData: {},
      dashboardData: {
        phone: 'loading...',
        email: 'loading...',
        memberId:'loading...',
        surname:'loading...'
      }
    }
  },
  created() {
    this.memberData = httpServ.getSessionJson('memberData')
    this.work_state = this.memberData[0].work_state
    this.enumeratorId = this.memberData[0].id
    this.startDate = this.memberData[0].startDate 
    this.stopDate = this.memberData[0].stopDate
    const stopDate = moment(this.stopDate)
    const today = moment(new Date())

    this.hasActiveAccount = moment(stopDate).isAfter(today, 'day')

    this.getDashboardData()
  },
  methods:{
    getDashboardData: function() {
      //if (this.enumeratorId !== undefined) {
      const formUrl = httpServ.getFarmerEnumerationtUrl('/wsg/drySession/dashboardData')
      const resp = httpServ.postFetch(formUrl,{ 'enumeratorId':this.enumeratorId })
      
      resp.then((result) => {
        //console.log('cooperative select is ', result)
        if (result.status === 200) {
          this.cooperatives = result.data.cooperatives
          this.farmers = result.data.farmers
        } else {
          this.snackbartext = result.message
          this.snackbar = true
        }
      })
      //}
    }
  }
}
</script>

<style scoped>
.v-alert--prominent .v-alert__icon.v-icon {
  font-size: 32px;
  color: green;
}
.v-alert {
  color: green !important;
}
.card-header-text {
  font-weight: 400 !important;
  color: rgba(0, 0, 0, 0.87);
  padding-bottom: 20px;
}
.d-card-height {
  min-height: 500px !important;
}

::v-deep .v-data-table td {
  height: 20px !important;
  font-size: 14px !important;
}

::v-deep .v-data-table th {
  font-size: 12px !important;
}

.reg-guide-text {
  font-size: 13px !important;
}
</style>
